// Override default variables before the import
$primary: #712cf9;
$secondary: #ffc300;
$success: #00ced1;
$dark: #0d022f;
$light: #c6abfd;
$info: #fff3cc;
$body-bg: $dark;
$font-family-sans-serif: "VT323", monospace;
$font-size-root: 24px;
$font-weight-base: 400;
$badge-font-size: 0.6em;

$enable-negative-margins: true;

$accordion-bg: $light;

$container-max-widths: (
  sm: 540px,
);

$border-widths: (
  1: 2px,
  2: 6px,
  3: 10px,
  4: 14px,
  5: 18px,
);

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;
$h7-font-size: $font-size-base * 0.75;
$h8-font-size: $font-size-base * 0.5;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
  8: $h8-font-size,
);

$nav-link-padding-y: 0.125rem; // Vertical padding
$nav-link-padding-x: 0.25rem; // Horizontal padding

$accordion-button-padding-y: 0.5rem;
$accordion-button-padding-x: 0.75rem;

$accordion-button-focus-box-shadow: none;

// Adjust the font size
$nav-link-font-size: 0.75rem; // Smaller font size

$nav-tabs-border-color: $dark;
$nav-tabs-link-active-bg: $info;
$nav-tabs-link-active-color: $dark;

$nav-link-color: $dark;
$nav-link-hover-color: $dark;

$nav-tabs-border-color: $info;
$nav-tabs-link-active-border-color: $info;
$nav-tabs-link-hover-border-color: $info;
.btn-none {
  @extend .bg-transparent;
  @extend .p-0;
  @extend .border-0;
  color: inherit;
}

.pixelated {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
}

#container {
  overflow-y: scroll;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 18px;
}

::-webkit-scrollbar-track {
  background-color: $primary;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0px;
}

.dropdown-menu[data-bs-popper] {
  left: auto !important;
  right: 0 !important;
}

table#mons-compare td {

  &:nth-child(1),
  &:nth-child(3) {
    width: 40% !important;
  }

  font-size: 18px;
}

table#mon-stats {
  font-size: 18px;
}

.outline-1 {
  outline: 1px inset var(--bs-primary);
}

.outline-light {
  outline-color: $light;
}

.badge {
  border-radius: inherit !important;
}

.badge.rounded-top {
  height: 2em;
}

.highlighted-mon {
  background-color: rgba(255, 195, 0, 0.5); /* Highlighted monster */
  border-radius: 5px;
}

.tab-content {
  @extend .px-1;
  background-color: $info; // Replace $dark with your preferred color variable or custom color
}
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";